@font-face {
  font-family: charlesFont;
  src: url("../assets/fonts/CharlesWrightBold.ttf");
  font-size: 18px;
  padding: 5px;
}
*{
  font-family: 'Roboto';
  color: #013183;
}
body {
  font-family: 'Roboto';
  background: white;
  height: auto;
}

.root {
  height: 100%;
}

.content {
  grid-area: content;
  
}

.menu {
  grid-area: menu;
  margin-top: 20px;
}

.portfolio {
  grid-area: portfolio;

}
.skills {
  grid-area: skills;
  display: flex;
  flex-direction: column;
}
.sidebar {
  grid-area: sidebar;
  background: white;
  justify-content: center;
  height: 60%;
  box-shadow: 0 4px 2px -2px gray;
  height: 100%;
  color: #013183;
    
}
.promos {
  grid-area: promos;
  margin-top: 15px;
}

.sticky {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.footer {
  grid-area: footer;
  background: #013183;
}

.container {
  font-size: 1.5 em;
  width: 100%;
  height: 100%;
  display: grid;
  box-sizing: border-box;
  grid-template-areas:
    "sidebar"
    "content"
    "menu"
    "portfolio"
    "promos"
    "skills"
    "footer ";
  grid-template-columns: 1fr;
  grid-template-rows: 50px auto auto 750px 600px 300px 400px;

}
.content {
  margin-top: 55px;
}
.rrss-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: space-between;
}

.rrss-logo{
  width: 65px;
  margin: 25px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
  border-radius: 8px;

}

.rrss-logo:hover {
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.45);

}
.rrss-logo-box {
  transition: transform 0.4s;
  transform: scale(1);
}
.rrss-logo-box:hover {
  transform: scale(1.2);

}

/* .content,
.skills,
.footer {
  padding: 2.5em;
} */
.sidebar {
  padding: 2%;
  text-align: center;
}

.under-big-title {
  border: 0.5px solid #e03939;
  width: 8%;
  margin-left: 15px;
  margin-bottom: 15px;
}
.category-container {
  margin: 15px 0px 70px 0px;
  
}

.menu-title {
  font-weight: 300;
  font-size: 24px;
  margin: 0 0 0 15px;

}
.stayhome-text {
  text-align: center;
  padding: 20px;
}
.menu-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  font-family: 'Roboto';
  align-content: space-around;
}

.menu-card {
  margin: 70px 5px 30px 5px;
  background-color: white;
  border-radius: 16px;
  stroke: 1px #2A4670;
  height: 350px;
  width: 45%;
  display: flex;
  flex-direction: column;
  color: rgb(45, 29, 6);
  filter: drop-shadow(2px 8px 16px rgba(24, 50, 115, 6%)) ;

}
.card-photo {
  width: 100%;
  height: 45%;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}
.menu-description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: justify;
  border: 1px solid #D7D7D7;
  border-radius: 20px;
  width: 100%;
  color: #013183;
  margin: -14px;
  background-color: white;
  margin-left: 0;
  height: 280px;
}

.menu-description span {
  padding: 15px;
  font-style: normal;
  font-weight: lighter;
  margin-top: 10px;
  margin-left: 10px;
  font-size: 22px;
}
.menu-description p {
  padding: 15px;
  margin-left: 10px;
  width: 60%;
  font-size: 14px;
}
.menu-description label {
  padding: 15px;
  margin-left: 10px;
  font-size: 22px;
  font-weight: 500;
  margin-top: auto;
  margin-bottom: 15px;
  
}

hr.under-title {
  border: 1px solid #FF0000;
  width: 60%;
  margin-top: -5px;
  margin-left: 25px;
  margin-bottom: 15px;
}
.portfolio-title {
  padding: 5px;
  font-size: 2em;
  font-weight: 400;
  color: #013183;
  text-align: center;
}

.subtitle {
  font-size: 1.3em;
  font-weight: 400;
  color: #293d5f;;
  text-align: justify;
  margin: 0 30px 30px;
}

.map-container {
  margin: 30px;
 
  
}

.contact-title {
  font-size: 2em;
  font-weight: 700;
}
.content-portfolio {
  max-width: 980px;
  margin: 15px auto;
  text-align: center;
}


.content-portfolio h1 {
  text-align: center;
  font-size: 2em;
  font-weight: 300;
  color: #2e828b;
}
.content-portfolio h2 {
  text-align: left;
  font-size: 2em;
  font-weight: 700;
  color: #2e828b;
}

.content-portfolio p {
  font-weight: 400;
  line-height: 1.8em;
  color: #98a0ad;
  text-align: justify;
  text-justify: inter-word;
}

.portfolio-btn {
  margin-bottom: 8%;
  display: flex;
  justify-content: center;
}
.skills-container {
  display: flex;
  flex-wrap: wrap;
  padding: 30px;
  justify-content: space-around;
  text-align: center;
}
.skills-container article {
  flex-basis: 30%;
}

nav ul {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  text-align: center;
}

nav li {
  list-style: none;
  padding: 1.1em 0;
  font-size: 20px;
}

nav li a {
  color: #013183;
  font-weight: 400;
  text-decoration: none;
  transition: 0.3s;
  margin: 15px 12px;
}

nav li a:hover {
  opacity: 1;
}

.intro {
  max-width: 960px;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 5%;
}

.intro img {
  width: 200px;
  border-radius: 100px;
}

.intro h1 {
  font-size: 2em;
  font-weight: 300;
  color: #2e828b;
}

.intro p {
  font-weight: 400;
  line-height: 1.8em;
  color: #98a0ad;
}

.action-btn {
  display: inline-block;
  text-decoration: none;
  color: white;
  font-weight: 700;
  background: #58c389;
  padding: 0.5em 2em;
  border-radius: 40px;
  margin: 1em;
  transition: 0.3s;
  cursor: pointer;
  outline: none;
  box-shadow: 0 5px #dad3d3;
}

.action-btn:hover {
  background-color: #35b28e;
}

.action-btn:active {
  background-color: #35b28e;
  box-shadow: 0 5px #dad3d3;
  transform: translateY(4px);
}

footer ul {
  max-width: 640px;
  margin: 2em auto;
  padding: 0;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

footer ul li {
  list-style: none;
  align-self: flex-end;
  width: auto;
}
img {
  width: 40%;
}
footer ul li a {
  text-decoration: none;
  color: #c1c6ce;
}
.link-svg p {
  font-size: 18px;
  margin-top: 0px;
}
footer ul li a svg {
  width: 60px;
}

.content-portfolio img {
  border-radius: 10px;
}

.android {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 6px;
}
.android-code {
  display: flex;
  justify-content: center;
  align-items: center;
}
.android svg {
  width: 30%;
  margin-right: 8px;
  margin-bottom: 3px;
}
.skills-container article a {
  text-decoration: none;
}
@media (min-width: 1040px) {
  .content {
    display: block;
  }
  .container {
    grid-template-areas:
      "sidebar"
      "content"
      "menu"
      "portfolio"
      "promos"
      "skills"
      "footer ";
    grid-template-columns: 1fr;
    grid-template-rows: 55px 700px auto 700px 600px auto 150px;
  }

  .london-logo {
    height: 100px;
    width: auto;
  }

  .map-content {
    display: flex;
    flex-direction: column-reverse;
  }
 

  nav ul {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
  }
  .sidebar {
    background: white;
    padding-top: 0.5em;
    height: 100%;
    
  }
  .menu {
    padding: 30px;
    margin-top: 0px;
  }
  .menu-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    font-family: 'Roboto';
  }
  .menu-card {
  
    background-color: white;
    border-radius: 16px;
    stroke: 1px #2A4670;
    height: 400px;
    width: 22%;
    display: flex;
    flex-direction: column;
    color: rgb(45, 29, 6);
    filter: drop-shadow(2px 8px 16px rgba(24, 50, 115, 6%)) ;
  
  }

  .category-container {
    margin: 15px 0px 70px 0px;
    padding: 20px;
  }
  .intro {
    text-align: left;
    margin: 2em auto;
  }

  .intro img {
    width: 300px;
    float: right;
    border-radius: 220px;
  }
  .intro h1 {
    font-size: 3em;
  }
  .skills-container {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    padding: 30px;
    justify-content: space-around;
    text-align: center;
  }
  .skills-container article {
    flex-basis: 15%;
    margin: 25px 0px;
    transition: transform 0.4s;
    transform: scale(1);
  }
  .skills-container article:hover {
    transform: scale(1.2);
  }

  .intro p {
    width: 60%;
  }

  .content-portfolio {
    display: flex;
    flex-direction: row;
    padding: 30px;
    justify-content: space-around;
  }
  .project-info-container {
    width: 50%;
    margin: 0px 30px;
  }
  .content-portfolio img {
    border-radius: 10px;
    width: 50%;
    height: 85%;
  }

  .link-svg {
    transition: transform 0.4s;
    transform: scale(1);
  }

  .link-svg:hover {
    transform: scale(1.2);
  }
  .carousel-container {
    padding: 30px;
  }
  footer ul {
    margin: 0 auto;
    padding: 2em 0;
    justify-content: space-around;
  }
}
@media (max-width: 1340px) {

  
  .content-portfolio {
    display: flex;
    flex-direction: column;
  }
  .project-info-container {
    width: 100%;
  }
  .content-portfolio img {
    width: 100%;
    height: auto;
  }
  .skills-container article {
    flex-basis: 30%;
  }

  .london-logo {
    height: 100px;
    width: auto;
  }
}
